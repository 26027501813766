/* Colours */
:root {
    --color-primary: #25BEC2;
    --color-primary-fade: rgba(37,190,194,0.7);
    --color-light: #e0fafb;
    --color-secondary: #f37653;
    --color-secondary-fade: rgba(243,118,83,0.7);
    --color-dark: #2d3a37;
    --color-grey-dark: #707070;
    --color-grey-light: #b6b6b6;
    --color-grey-light-fade: rgba(182,182,182,0.7);
    --color-failure: #B00020;
    --color-failure-fade: rgba(176,0,32,0.7);
    --color-success:  #87E575;
    --color-success-fade: rgba(135,229,117,0.7);
    --font-main: "Rubik";
    --font-secondary: "Roboto";
}
.text-input {
    border: solid 2px var(--color-primary);
    padding: 5px 1.5em;
    height: 3em;
    border-radius: 1.5em;
    background-color: #ffffff;
}
.text-input input {
    vertical-align: middle;
    border-color: transparent;
    color: var(--color-primary);
    width: calc(100% - 2em);
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.text-input input:focus{
    border-color: transparent;
    outline: none !important;
}
.input-icon {
    font-size: 1.5em;
    vertical-align: middle;
    color: var(--color-grey-light);
}
.text-input.invalid{
    border-color: var(--color-failure);
}
.text-input.disabled{
    border-color: var(--color-grey-light);
}
.help-text {
    margin: 0;
    padding-left: 1.5em;
    font-family: 'Roboto', sans-serif;
    font-size: 0.75em;
    font-weight: 500;
    color: var(--color-primary);
}
.help-text.invalid{
    color: var(--color-failure);
}
.help-text.disabled{
    color: var(--color-grey-light);
}
.long-input {
    width: 100% !important;
    padding: 10px 1em;
    border: solid 2px var(--color-primary);
    border-radius: 1.5em;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--color-primary);
}
.long-input:focus {
    outline: none !important;
}
.course-thumbnail {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    border-radius: 25px;
    position: relative;
}
.time-label {
    background-color: var(--color-primary);
    position: absolute;
    padding: 5px 10px;
    bottom: 20px;
    left: 20px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #ffffff;
    border-radius: 5px;
}
.time-label i{
    margin-right: 5px;
}
.course-card {
    background-color: var(--color-light);
    padding: 25px;
    border-radius: 20px;
}
.course-title {
    font-family: 'Rubik', sans-serif;
    font-size: 2em;
    font-weight: 700;
    color: var(--color-dark);
    margin-top: 0;
}
.course-description {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25em;
    font-weight: 400;
    text-align: justify;
}
.course-summary {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    color: var(--color-primary);
    margin-bottom: 0;
}
.course-summary .fa-user-circle {
    margin-right: 5px;
}
.course-summary .fa-circle {
    margin: 0 10px;
    font-size: 0.4em;
    vertical-align: middle;
}
.course-price-col{
    text-align: right;
}
.course-price {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    background-color: var(--color-primary);
    text-align: center;
    padding: 5px 25px;
    color: #ffffff;
    border-radius: 1.5em;
    display: inline-block;
    margin-top: 15px;
}
.topic-image {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-size: cover;
}
.topic-name {
    background-color: var(--color-light);
    padding: 15px 10px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    color: var(--color-primary);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.course-banner {
    background-size: cover;
    border-radius: 25px;
}
.banner-tint {
    background: linear-gradient(to bottom, transparent 25%, var(--color-grey-dark));
    border-radius: 5px;
}
.banner-info {
    padding: 100px 20px 20px 20px;
}
.banner-summary {
    font-family: 'Roboto', sans-serif;
    color: var(--color-light);
    font-size: 1.25em;
    font-weight: 700;
    margin: 5px 0;
}
.banner-summary .fa-clock {
    margin-right: 5px;
}
.banner-summary .fa-circle {
    margin: 0 10px;
    font-size: 0.5em;
    vertical-align: middle;
}
.banner-title {
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 2.5em;
    margin: 5px 0;
    color: #ffffff;
}
.banner-description {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #ffffff;
    margin: 5px 0;
}
.banner-progress {
    height: 10px;
    background-color: var(--color-primary);
    border-radius: 0 5px 5px 5px;
}
.mentor-card {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    border-radius: 25px;
}
.card-tint {
    background: linear-gradient(to bottom, transparent 40%, var(--color-grey-dark));
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: 25px;
}
.mentor-info {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    padding-bottom: 10px;
}
.mentor-name {
    margin: 5px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    color: var(--color-primary);
}
.star-icon {
    color: #ffffff;
    font-size: 0.5em;
    background-color: var(--color-primary);
    padding: 5px;
    margin-left: 5px;
    border-radius: 50%;
    vertical-align: middle;
}
.mentor-domain {
    margin: 5px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25em;
    font-weight: 500;
    color: #ffffff;
}
.level-badge {
    background-color: var(--color-light);
    padding: 2em;
    border-radius: 2em;
}
.badge-icon {
    text-align: center;
}
.badge-image {
    height: 8em;
    width: 8em;
}
.badge-name {
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 1.75em;
    margin: 10px 0;
    color: var(--color-dark);
}
.badge-info {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.25em;
    color: var(--color-grey-dark);
    margin: 15px 0 50px 0;
}
.probano-primary {
    border: none;
    padding: 15px 25px;
    background-color: var(--color-primary);
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.25em;
    border-radius: 2em;
}
.probano-primary:hover{
    background-color: var(--color-primary-fade);
    cursor: pointer;
}
.combo-card-container {
    position: relative;
}
.combo-student-img {
    width: 15em;
    height: 15em;
    margin-top: 3em;
    border-radius: 1.5em;
}
.parent-container {
    position: absolute;
    top: 0;
    left: 12em;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 50%;
}
.combo-parent-img {
    height: 6em;
    width: 6em;
    border-radius: 50%;
}
.probono-button {
    border: none;
    padding: 10px 25px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
.probono-button.btn-primary {
    background-color: var(--color-primary);
    color: #ffffff;
}
.probono-button.btn-primary:hover{
    background-color: var(--color-primary-fade);
    cursor: pointer;
}
.probono-button.btn-danger {
    background-color: var(--color-failure);
    color: #ffffff;
}
.probono-button.btn-danger:hover{
    background-color: var(--color-failure-fade);
    cursor: pointer;
}
.probono-button.btn-secondary {
    background-color: var(--color-secondary);
    color: #ffffff;
}
.probono-button.btn-secondary:hover{
    background-color: var(--color-secondary-fade);
    cursor: pointer;
}
.probono-button.btn-success {
    background-color: var(--color-success);
    color: #ffffff;
}
.probono-button.btn-success:hover{
    background-color: var(--color-success-fade);
    cursor: pointer;
}
.probono-button.btn-disabled {
    background-color: var(--color-grey-light);
    color: #ffffff;
}
.probono-button.btn-disabled:hover {
    background-color: var(--color-grey-light-fade);
    cursor: pointer;
}









/* Loader Animation 1 */
.image{
    border-radius: 10px;
}
.text-line{
    border-radius: 5px;
}
.loader-wrapper {
    background: transparent;
    display: inline-block;
    flex-direction: column;
}

.loader-wrapper-cell {
    display: flex;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  .animated-background, .image, .text-line {
    -webkit-animation-duration: 1.25s;
            animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
            animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #d3e4f1  8%, #eaf1f5 18%, #d3e4f1  33%);
    /* background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%); */
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }

/* Loader Animation 1 */
/* Loader Animation 2 */
  .cssload-thecube {
      width: 73px;
      height: 73px;
      margin: 0 auto;
      margin-top: 49px;
      position: relative;
      transform: rotateZ(45deg);
          -o-transform: rotateZ(45deg);
          -ms-transform: rotateZ(45deg);
          -webkit-transform: rotateZ(45deg);
          -moz-transform: rotateZ(45deg);
  }
  .cssload-thecube .cssload-cube {
      position: relative;
      transform: rotateZ(45deg);
          -o-transform: rotateZ(45deg);
          -ms-transform: rotateZ(45deg);
          -webkit-transform: rotateZ(45deg);
          -moz-transform: rotateZ(45deg);
  }
  .cssload-thecube .cssload-cube {
      float: left;
      width: 50%;
      height: 50%;
      position: relative;
      transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
  }
  .cssload-thecube .cssload-cube:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10%;
      background-color: rgb(29,135,201);
      animation: cssload-fold-thecube 2.76s infinite linear both;
          -o-animation: cssload-fold-thecube 2.76s infinite linear both;
          -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
          -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
          -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
      transform-origin: 100% 100%;
          -o-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
          -webkit-transform-origin: 100% 100%;
          -moz-transform-origin: 100% 100%;
  }
  .cssload-thecube .cssload-c2 {
      transform: scale(1.1) rotateZ(90deg);
          -o-transform: scale(1.1) rotateZ(90deg);
          -ms-transform: scale(1.1) rotateZ(90deg);
          -webkit-transform: scale(1.1) rotateZ(90deg);
          -moz-transform: scale(1.1) rotateZ(90deg);
  }
  .cssload-thecube .cssload-c3 {
      transform: scale(1.1) rotateZ(180deg);
          -o-transform: scale(1.1) rotateZ(180deg);
          -ms-transform: scale(1.1) rotateZ(180deg);
          -webkit-transform: scale(1.1) rotateZ(180deg);
          -moz-transform: scale(1.1) rotateZ(180deg);
  }
  .cssload-thecube .cssload-c4 {
      transform: scale(1.1) rotateZ(270deg);
          -o-transform: scale(1.1) rotateZ(270deg);
          -ms-transform: scale(1.1) rotateZ(270deg);
          -webkit-transform: scale(1.1) rotateZ(270deg);
          -moz-transform: scale(1.1) rotateZ(270deg);
  }
  .cssload-thecube .cssload-c2:before {
      animation-delay: 0.35s;
          -o-animation-delay: 0.35s;
          -ms-animation-delay: 0.35s;
          -webkit-animation-delay: 0.35s;
          -moz-animation-delay: 0.35s;
  }
  .cssload-thecube .cssload-c3:before {
      animation-delay: 0.69s;
          -o-animation-delay: 0.69s;
          -ms-animation-delay: 0.69s;
          -webkit-animation-delay: 0.69s;
          -moz-animation-delay: 0.69s;
  }
  .cssload-thecube .cssload-c4:before {
      animation-delay: 1.04s;
          -o-animation-delay: 1.04s;
          -ms-animation-delay: 1.04s;
          -webkit-animation-delay: 1.04s;
          -moz-animation-delay: 1.04s;
  }



  @keyframes cssload-fold-thecube {
      0%, 10% {
          transform: perspective(136px) rotateX(-180deg);
          opacity: 0;
      }
      25%,
                  75% {
          transform: perspective(136px) rotateX(0deg);
          opacity: 1;
      }
      90%,
                  100% {
          transform: perspective(136px) rotateY(180deg);
          opacity: 0;
      }
  }

  @-o-keyframes cssload-fold-thecube {
      0%, 10% {
          -o-transform: perspective(136px) rotateX(-180deg);
          opacity: 0;
      }
      25%,
                  75% {
          -o-transform: perspective(136px) rotateX(0deg);
          opacity: 1;
      }
      90%,
                  100% {
          -o-transform: perspective(136px) rotateY(180deg);
          opacity: 0;
      }
  }

  @-ms-keyframes cssload-fold-thecube {
      0%, 10% {
          -ms-transform: perspective(136px) rotateX(-180deg);
          opacity: 0;
      }
      25%,
                  75% {
          -ms-transform: perspective(136px) rotateX(0deg);
          opacity: 1;
      }
      90%,
                  100% {
          -ms-transform: perspective(136px) rotateY(180deg);
          opacity: 0;
      }
  }

  @-webkit-keyframes cssload-fold-thecube {
      0%, 10% {
          -webkit-transform: perspective(136px) rotateX(-180deg);
          opacity: 0;
      }
      25%,
                  75% {
          -webkit-transform: perspective(136px) rotateX(0deg);
          opacity: 1;
      }
      90%,
                  100% {
          -webkit-transform: perspective(136px) rotateY(180deg);
          opacity: 0;
      }
  }

  @-moz-keyframes cssload-fold-thecube {
      0%, 10% {
          -moz-transform: perspective(136px) rotateX(-180deg);
          opacity: 0;
      }
      25%,
                  75% {
          -moz-transform: perspective(136px) rotateX(0deg);
          opacity: 1;
      }
      90%,
                  100% {
          -moz-transform: perspective(136px) rotateY(180deg);
          opacity: 0;
      }
  }
/* Loader Animation 2 */
