/* Colors */

.pb-primary-color {
  color: var(--color-primary);
}

.pb-secondary-color {
  color: var(--color-secondary);
}

.pb-tertiary-color {
  color: #E30D95;
}

.pb-secondary-color-bg {
  background-color: var(--color-secondary);
}

.pb-primary-color-bg {
  background-color: var(--color-primary);
}

.pb-grey-light-color {
  color: var(--color-grey-light);
}

.pb-grey-light-bg {
  background-color: #f1f1f1;
}

.pb-green-color {
  color: #5da84d;
}

.pb-grey-light-color-bg {
  background-color: var(--color-grey-light);
}

.pb-fade-grey-light-color-bg {
  background-color: #f6f6f6;
}

.hcl-report-bg-color {
  background-color: #e6f1f8;
}

.hcl-report-bg-dark-color {
  background-color: #006cb7;
}

.hcl-report-text-color {
  color: #006cb7;
}

.rubik {
  font-family: "Rubik";
}

.rubik-bold {
  font-family: "Rubik";
  font-weight: bold;
}

.roboto {
  font-family: "Roboto";
}

.roboto-bold {
  font-family: "Roboto";
  font-weight: bold;
}

.roboto-medium {
  font-family: "Roboto";
  font-weight: 500;
}
